.container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
  z-index: 16;
  opacity: 0;
  transition: opacity 0.3s;
  box-sizing: border-box;
}

.popup {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 86%;
  padding: 7px;
  background-color: white;
  border-radius: 20px;
  padding-bottom: 12px;
  max-height: 70vh;
}

.popupMagnum {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 86%;
  padding: 7px;
  background-color: var(--magnum-brown);
  border-radius: 20px;
  padding-bottom: 12px;
  max-height: 70vh;
}

.close {
  position: absolute;
  top: 2%;
  right: 5%;
  cursor: pointer;
  color: var(--background);
  font-size: 35px;
  font-weight: 300;
  z-index: 999;
}
.clickable {
  cursor: pointer;
}
.clickable:hover {
  opacity: 0.7;
}
.clickable:active {
  transform: translateY(1px);
}
.icon {
  max-width: 70px;
  margin-top: 2vh;
}

.largeIcon {
  width: 33vw;
  height: auto;
  max-width: initial;
}

.imageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.image {
  border-radius: 25px;
  width: 60%;
}

.title {
  font-weight: 700;
  text-align: center;
  margin-top: 2vh;
  font-size: 24px;
  color: var(--black);
}

.message {
  width: 80%;
  word-wrap: break-word;
  font-size: 14px;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 1vh;
  font-weight: 400;
  opacity: 0.8;
}

.button {
  width: 94%;
  height: 50px;
  margin-top: 5px;
}

.magnumButton {
  width: 94%;
  height: 50px;
  margin-top: 5px;
  color: var(--dark-grey, #2d2d2d);
  text-align: center;
  font-family: Futura;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 1px;
  background: url('src/assets/images/magnum/magnumGold2.png'),
    lightgray -627.775px -156.729px / 393.333% 916.964% no-repeat;
  background-size: cover;
}

.magnumButton:hover {
  width: 94%;
  height: 50px;
  margin-top: 5px;
  color: var(--dark-grey, #2d2d2d);
  text-align: center;
  font-family: Futura;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 1px;
  background: #e5c244;
}

.cancel {
  width: 94%;
  height: 50px;
  margin-top: 1vh;
}

.cancelMagnum {
  width: 94%;
  height: 50px;
  margin-top: 1vh;
}

.cancelMagnum:hover {
  width: 64%;
  height: 50px;
  margin-top: 1vh;
  background: var(--magnum-brown);
}

.scrollText {
  margin-top: 30px;
  overflow-y: scroll;
  max-height: 100%;
  max-width: 100%;
  overflow-x: hidden;
}
.img {
  padding-top: 50px;
}
.otpTitle {
  font-family: OpenSansRegular;
  font-size: 14px;
  color: #000;
  text-align: center;
  line-height: 18px;
  padding: 10px 10%;
}
.submitButton {
  margin: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70%;
  border-radius: 36px;
  height: 5.5vh;
  min-height: 40px;
  background: var(--algida-red);
  text-shadow: 1px 1px 8px rgba(0, 0, 0, 0.8);
}
.submitButtonDisabled {
  background: gray !important;
  cursor: not-allowed !important;
}
.button__text {
  font-size: 14px;
  color: #fff;
  font-family: OpenSansSemiBold;
}
.codeText {
  font-family: OpenSansRegular;
  font-size: 14px;
  color: #000;
}
.resend {
  font-family: OpenSansBold;
  text-decoration: underline;
  font-size: 14px;
  color: #000;
  margin-left: 5px;
}
.clickable {
  cursor: pointer;
}
.clickable:hover {
  opacity: 0.7;
}
.clickable:active {
  transform: translateY(1px);
}
.successText {
  color: var(--light-red);
  font-size: 14px;
  font-family: OpenSansRegular;
  align-self: center;
  margin: -5px;
  width: 80%;
  text-align: center;
}

.goBackAlgida {
  margin-top: 10px;
  color: white;
  background: var(--algida-red);
  width: 90%;
  height: 50px;
  margin-top: 5px;
  color: #fff;
  text-align: center;
  font-family: Futura;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 1px;
}

@media (min-width: 600px) {
  .popup {
    width: 75vw;
    padding: 3.5vh 20px;
  }

  .popupMagnum {
    width: 75vw;
    padding: 3.5vh 20px;
  }

  .codeText {
    font-family: OpenSansRegular;
    font-size: 20px;
    color: #000;
  }
  .resend {
    font-family: OpenSansBold;
    text-decoration: underline;
    font-size: 20px;
    color: #000;
    margin-left: 5px;
  }
  .button__text {
    font-size: 14px;
    color: #fff;
    font-family: OpenSansSemiBold;
  }
  .submitButton {
    margin: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 33%;
    border-radius: 36px;
    height: 5.5vh;
    min-height: 40px;
    background: var(--algida-red);
    text-shadow: 1px 1px 8px rgba(0, 0, 0, 0.8);
  }
  .title {
    font-size: 17px;
    margin-bottom: 1.5vh;
  }
  .otpTitle {
    font-family: OpenSansRegular;
    font-size: 20px;
    color: #000;
    text-align: center;
    line-height: 30px;
    padding: 10px 10%;
  }
  .imageBg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 30%;
    border-radius: 30px;
  }

  .image {
    width: 40vw;
    z-index: 5;
    margin-bottom: 1.5vh;
  }

  .button {
    margin-top: 2vh;
  }

  .button,
  .cancel {
    width: 50%;
  }
}

@media (min-width: 1070px) {
  .popup {
    max-width: 500px;
    padding: 20px 20px;
    box-sizing: border-box;
  }

  .popupMagnum {
    max-width: 500px;
    padding: 20px 20px;
    box-sizing: border-box;
  }

  .icon {
    width: 80px;
    height: 80px;
    max-width: initial;
    margin-bottom: 25px;
  }

  .largeIcon {
    width: 110px;
    height: auto;
    max-width: initial;
  }

  .title {
    margin: 0 0 20px 0;
    font-size: 21px;
  }

  .message {
    margin: 0 0 10px 0;
    font-size: 16px;
    line-height: 22px;
  }

  .popup.imageMode {
    max-width: 500px;
    padding: 30px 15px;
  }

  .popupMagnum.imageMode {
    max-width: 500px;
    padding: 30px 15px;
  }

  .message {
    width: 80%;
  }

  .image {
    width: 300px;
    width: 250px;
  }

  .button,
  .cancel {
    width: 295px;
    height: 50px;
    font-size: 14px;
  }
}
