.content {
  border-radius: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  min-width: 650px;
  background: url('src/assets/images/magnum/magnumGold2.png'),
    lightgray 0px 0px / 100% 100% no-repeat;
  background-size: cover;
  /* position: relative; */
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.imageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
}

.drawImage {
  width: 100%;
  height: 260px;
  border-radius: 10px;
  margin-bottom: 15px;
  margin-top: 15px;
}

.close {
  display: none;
}

.closeX {
  position: absolute;
  top: 2%;
  right: 5%;
  cursor: pointer;
  color: var(--magnum-dark);
  font-size: 35px;
  font-weight: 300;
  z-index: 999;
}
.clickable {
  cursor: pointer;
}
.clickable:hover {
  opacity: 0.7;
}
.clickable:active {
  transform: translateY(1px);
}

.headerTextCard {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  margin-top: 10px;
}

.headerText {
  color: #522c21;
  text-align: center;
  font-family: Futura;
  font-size: 26.953px;
  font-style: normal;
  font-weight: 700;
  line-height: 18.53px;
  margin-top: 10px;
}

.sadIconImage {
  width: 66px;
  height: 50px;
  margin-top: 20px;
  margin-bottom: 15px;
}

.bottomCard {
  width: 50%;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.bottomText {
  color: #522c21;
  text-align: center;
  font-family: Futura;
  font-size: 15.161px;
  font-style: normal;
  font-weight: 700;
  line-height: 22.741px;
}

.buttonCard {
  width: 100%;
  display: flex;
  justify-content: center;
}

.buttonStyle {
  width: 209.724px;
  height: 47.167px;
  border: 0px;
  border-radius: 27.795px;
  background: #522c21;
  color: #fff;
  font-family: Futura;
  font-size: 16.845px;
  font-style: normal;
  font-weight: 700;
  line-height: 11.912px;
  cursor: pointer;
}

.buttonStyle:active {
  width: 209.724px;
  height: 47.167px;
  border: 0px;
  border-radius: 27.795px;
  background: #6a382b;
  color: #fff;
  font-family: Futura;
  font-size: 16.845px;
  font-style: normal;
  font-weight: 700;
  line-height: 11.912px;
  cursor: pointer;
}

.extraContainer {
  width: 100%;
  /* min-width: 710px; */
  height: 180px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #36160d;
  position: absolute;
  z-index: 1;
  /* right: -190px; */
  top: 0px;
  border-radius: 20px;
}

.extraCard {
  margin-left: 30px;
}

.extraContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 10px 0px 10px;
  color: var(--magnum-gold);
}

.extraFirstText {
  text-align: center;
  font-family: Futura;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 18.951px;
  letter-spacing: 0.758px;
  text-transform: uppercase;
  background: var(
    --linear-2,
    linear-gradient(180deg, #ffc700 0%, #e5c244 100%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.extraSecondText {
  color: var(--background-blue, #f6f8fa);
  text-align: center;
  font-family: Futura;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.extraScoreText {
  color: var(--background-blue, #f6f8fa);
  text-align: right;
  font-family: Futura;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}

.extraWinText {
  text-align: center;
  font-family: Futura;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
  background: var(
    --linear-2,
    linear-gradient(180deg, #ffc700 0%, #e5c244 100%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@media (min-width: 600px) {
  .content {
    position: relative;
    width: 50%;
    min-width: 500px;
    height: initial;
    padding-bottom: 30px;
    padding-top: 0;
    border-radius: 20px;
    background: url('src/assets/images/magnum/magnumGold2.png'),
      lightgray 0px 0px / 100% 100% no-repeat;
    background-size: cover;
    position: relative;
  }

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    min-height: 450px;
    position: relative;
  }

  .extraContainer {
    padding-top: 20px;
    width: 100%;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #36160d;
    position: absolute;
    z-index: -1;
    left: 0px;
    top: 530px;
    bottom: 20px;
    border-radius: 20px;
  }
}

@media (min-width: 1100px) {
  .content {
    width: 30vw;
    min-width: 500px;
    position: relative;
  }

  .container  {
    position: relative;
  }

  .extraContainer {
    width: 220px;
    height: 97%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #36160d;
    position: absolute;
    z-index: -1;
    left: 480px;
    top: 0px;
    border-radius: 20px;
  }
}
