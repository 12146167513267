.buttonContainer {
  width: 200px;
  height: 50px;
  border: 0px;
  border-radius: 50px;
  color: var(--magnum-dark);
  background: url('src/assets/images/magnum/magnumGold.png'),
    lightgray -360.777px -269.245px / 393.333% 916.964% no-repeat;
  -webkit-box-shadow: 0px 0px 30px 3px rgba(0, 0, 0, 0.78);
  -moz-box-shadow: 0px 0px 30px 3px rgba(0, 0, 0, 0.78);
  box-shadow: 0px 0px 30px 3px rgba(0, 0, 0, 0.78);
}

.buttonContainer:hover {
  cursor: pointer;
}

.buttonContainer:active {
  background: #c2a335;
}

.buttonText {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 150px;
  font-size: 15px;
}
