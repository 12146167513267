.content {
  width: 100%;
  height: 100vh;
  border-radius: 0;
  justify-content: flex-start;
  transition: bottom 0.3s;
  background: rgba(255, 255, 255, 0.747)
    url(src/assets/images/code-enter/background.png) center / cover
    no-repeat;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-top: 30vh;
  box-sizing: border-box;
}

.close {
  display: none;
}

.coverImage {
  display: none;
  width: 100%;
}

.input {
  width: 90%;
  height: 75px;
  border-radius: 54px;
  border: none;
  outline: none;
  margin-bottom: 2.5vh;
  padding: 0 5%;
  box-sizing: border-box;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-align: center;
  box-shadow: 1px 1px 8px rgba(50, 50, 50, 0.2);
}

.input::placeholder {
  font-weight: normal;
  letter-spacing: initial;
  font-size: 16px;
  text-transform: initial;
}

.desktopText {
  display: none;
  font-size: 14px;
  font-weight: 500;
  margin-top: -30px;
}

.submitButton {
  width: 55%;
  height: 55px;
}

.submitButton img {
  width: 17px;
  margin-left: 10px;
}

@media (min-width: 600px) {
  .content {
    position: relative;
    width: 80%;
    height: initial;
    padding-bottom: 30px;
    padding-top: 0;
    border-radius: 30px;
    background: #fefafa;
  }

  .container {
    padding-top: 0;
  }

  .coverImage {
    display: initial;
  }

  .desktopText {
    display: initial;
  }

  .input {
    width: 70%;
    height: 60px;
    margin-top: 30px;
    margin-bottom: 20px;
  }

  .submitButton {
    width: 70%;
    height: 50px;
    margin-bottom: 10px;
  }
}

@media (min-width: 1100px) {
  .content {
    width: 30vw;
    max-width: 500px;
    width: 40%;
  }
}
