.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0;
  padding-top: 5vh;
  padding-bottom: 5vh;
  box-sizing: border-box;
  transition: opacity 0.3s;
  background: rgba(56, 56, 56, 0.3);
  overflow: auto;
  z-index: 5;
}

.modalContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px;
  justify-content: flex-start;
  background: none;
  box-shadow: none;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
  height: initial;
  position: relative;
  background: white;
  border-radius: 20px;
  padding: 4vh 5% 2vh 5%;
  box-sizing: border-box;
}

.close {
  display: none;
}

.coverImage {
  width: 30%;
}
.title {
  color: var(--night-grey);
  font-size: 14px;
  text-align: center;
}
.submitButton {
  width: 70%;
}

.resendField {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-size: 13px;
}

.resendButton {
  display: inline;
  width: initial;
  font-size: 14px;
  color: var(--red);
}

@media (min-width: 600px) {
  .modal {
    background: rgba(56, 56, 56, 0.3);
    overflow: auto;
    padding: 0;
  }

  .modalContent {
    background: white;
    max-width: 470px;
    border-radius: 30px;
    padding-bottom: 10px;
  }

  .content {
    position: relative;
    width: 100%;
    height: initial;
    padding: 0 0 10px 0;
    border-radius: 0;
    background: none;
    margin: 0;
  }

  .productField {
    margin-top: 2vh;
  }
  .coverImage {
    display: block;
  }
}
