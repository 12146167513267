.modal {
  position: absolute;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 30, 0.6);
  z-index: 6;
  opacity: 0;
  transition: opacity 0.2s;
}

.drawer {
  opacity: 1;
}

.drawerThick {
  width: 40px;
  height: 4px;
  background: #c4c4c4;
  border-radius: 7px;
  margin-top: 10px;
}

.content {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: white;
  border-radius: 30px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.308);
  transition: bottom 0.3s;
  box-sizing: border-box;
}

.drawer .content {
  position: fixed;
  bottom: -100vh;
  left: 0;
  justify-content: flex-start;
  height: initial;
  min-height: 40%;
  border-radius: 20px 20px 0 0;
}

.close {
  position: absolute;
  top: 1%;
  right: 3%;
  color: black;
  padding: 5px;
  cursor: pointer;
  font-size: 28px;
  font-weight: 300;
}

.drawer .close {
  display: none;
}

.close:hover {
  color: var(--algida-red);
}

@media (min-width: 600px) {
  .content {
    width: 50%;
    height: 50%;
  }

  .drawerThick {
    display: none;
  }

  .modal {
    opacity: 0;
  }

  .drawer .close {
    display: initial;
  }
}
