.container,
.stockOutContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 80%;
  border: 1px solid lightgray;
  border-radius: 16px;
  box-sizing: border-box;
  background: white;
  cursor: pointer;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
  padding: 10px 10px 10% 10px;
  /* padding-bottom:22%; */
  margin: 10px 0;
}

.stockOutContainer {
  cursor: default;
}

.stockOutContainer:hover,
.container:hover {
  background-color: whitesmoke;
}

.image {
  width: 20%;
}

.name {
  display: block;
  overflow: hidden;
  font-size: 12px;
  color: var(--text-color);
  text-align: center;
  margin-left: 10px;
}
.bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 22%;
  background: var(--algida-red);
  color: white;
  font-size: 14px;
  border-radius: 0 0 16px 16px;
  text-align: center;
}

.stockOutContainer .bottom {
  background: #5d6673;
}
.top {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.topLeft {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
}
.buttonText {
  color: #fff;
  font-size: 12px;
  margin: 0 10px;
}
.textCon {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.name2 {
  display: block;
  overflow: hidden;
  font-size: 10px;
  color: gray;
  text-align: center;
  margin-left: 10px;
}
.bottomDis {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
@media (min-width: 600px) {
  .container,
  .stockOutContainer {
    justify-content: center;
  }
  .name {
    display: block;
    overflow: hidden;
    font-size: 18px;
    color: var(--text-color);
    text-align: center;
    margin-left: 10px;
  }
  .name2 {
    display: block;
    overflow: hidden;
    font-size: 16px;
    color: gray;
    text-align: center;
    margin-left: 10px;
  }
}

@media (min-width: 1070px) {
  .container,
  .stockOutContainer {
    padding-top: 30px;
  }
}
