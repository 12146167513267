.container {
  margin: 0 0 2vh 0;
}

.collapsibleButton {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: solid var(--algida-red);
  font-weight: 600;
  font-size: 13px;
  padding: 0.8em;
  outline: none;
}

.collapsibleMagnumButton {
  display: flex;
  background: white;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: solid 1px rgba(93, 102, 115, 0.2);
  font-weight: 600;
  font-size: 13px;
  padding: 0.8em;
  outline: none;
}

.openedContainer {
  padding: 0.8em;
  font-weight: 600;
  font-size: 13px;
}

.openedHtml {
  color: var(--magnum-black);
}

.arrowMagnumImage {
  height: 5px;
  width: 12px;
}

.arrowImage {
  width: 20px;
  transform: rotate(90deg);
}