.content {
  border-radius: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  background: url('src/assets/images/magnum/magnumCodeBack.png'), lightgray 0px 0px / 100% 100% no-repeat;
  background-size: cover;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-top: 30vh;
  box-sizing: border-box;
  min-height: 400px;
  position: relative;
}

.magnumCodeImageContainer {
  z-index: -1;
  position:absolute;
  bottom: 70px
}

.magnumCodeImage {
  width: 100%;
  height: 300px;
}

.codeContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
  position: relative;
}

.close {
  display: none;
}


.input {
  width: 90%;
  height: 75px;
  border-radius: 54px;
  border: none;
  outline: none;
  margin-bottom: 2.5vh;
  padding: 0 5%;
  box-sizing: border-box;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-align: center;
  box-shadow: 1px 1px 8px rgba(50, 50, 50, 0.2);
}

.input::placeholder {
  font-weight: normal;
  letter-spacing: initial;
  font-size: 16px;
  text-transform: initial;
}

@media (min-width: 600px) {
  .content {
    position: relative;
    width: 50%;
    min-width: 450px;
    height: initial;
    padding-bottom: 30px;
    padding-top: 0;
    border-radius: 30px;
    background: url('src/assets/images/magnum/magnumCodeBack.png'), lightgray 0px 0px / 100% 100% no-repeat;
    background-size: cover;
  }

  .container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 100%;
    padding-top: 30vh;
    box-sizing: border-box;
    min-height: 400px;
    position: relative;
  }

  .input {
    width: 70%;
    height: 60px;
    margin-top: 30px;
    margin-bottom: 20px;
  }
}

@media (min-width: 1100px) {
  .content {
    width: 30vw;
    max-width: 500px;
    width: 40%;
  }
}
