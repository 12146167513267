.content {
  width: 80%;
  height: 30%;
  font-size: 12px;
  padding-bottom: 20px;
  font-size: 12px;
}

.contentLegal {
  width: 100%;
  height: 100vh;
  border-radius: 0;
  justify-content: flex-start;
  transition: bottom 0.3s;
}

.message {
  width: 100%;
  font-size: 10px;
}

.checkboxCard {
  display: flex;
  flex-direction: row;
  padding-left: 10px;
}

.buttonCard {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.legalCard {
  overflow-y: scroll;
  margin-top: 5px;
}

.legalContent {
  overflow-y: scroll;
  height: 100%;
  width: 100%;
  padding-top: 10px;
  text-align: center;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.checkbox {
  width: 40px;
  height: 30px;
  margin-top: 15px;
}

.close {
  display: none;
}

.desktopText {
  display: none;
  font-size: 14px;
  font-weight: 500;
  margin-top: -30px;
}

.backButton {
  /* display: none; */
  position: absolute;
  top: 25px;
  left: 4%;
  width: 30px;
  height: 30px;
  background: url(src/assets/images/account/back-button.png) center / cover
    no-repeat;
  cursor: pointer;
  border-radius: 50%;
}

.headerCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80px;
}

@media (min-width: 600px) {
  .content {
    position: relative;
    width: 70%;
    height: 300px;
    padding-top: 25px;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 25px;
    border-radius: 30px;
    font-size: 14px;
  }

  .contentLegal {
    width: 80%;
    height: 70%;
    border-radius: 30px;
  }

  .message {
    overflow-y: scroll;
    height: 100%;
    width: 100%;
    padding-top: 10px;
    font-size: 14px;
  }
  
  .checkboxCard {
    display: flex;
    flex-direction: row;
    padding-left: 10px;
    align-items: center;
  }
  
  .buttonCard {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }

  .legalCard {
    overflow-y: scroll;
    padding-top: 2px;
  }
  

  .container {
    padding-top: 0;
  }

  .coverImage {
    display: initial;
  }

  .desktopText {
    display: initial;
  }

  .input {
    width: 70%;
    height: 60px;
    margin-top: 30px;
    margin-bottom: 20px;
  }

  .submitButton {
    width: 70%;
    height: 50px;
    margin-bottom: 10px;
  }

  .backButton {
    display: block;
  }
}

@media (min-width: 1100px) {
  .content {
    padding-top: 20px;
    width: 700px;
    height: 300px;
    border-radius: 30px;
    font-size: 16px;
  }

  .contentLegal {
    height: initial;
    max-width: 1100px;
    height: 75%;
    border-radius: 30px;
  }

  .backButton:hover {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.568);
  }

  .message {
    overflow-y: scroll;
    height: 100%;
    width: 100%;
    padding-top: 10px;
    font-size: 14px;
  }
  
  .checkboxCard {
    display: flex;
    flex-direction: row;
    padding-left: 10px;
    align-items: center;
  }
  
  .buttonCard {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }

  .legalCard {
    overflow-y: scroll;
    padding-top: 3px;
  }
}
