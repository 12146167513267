.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100vw;
  height: 100vh;
  max-width: initial;
  box-sizing: border-box;
  background: rgb(248, 248, 248);
  padding: 0;
  border-radius: 0;
}
.close {
  display: flex !important;
  justify-content: center;
  align-items: center;
  background-color: whitesmoke;
  color: #000;
  width: 25px;
  height: 25px;
  border-radius: 8px;
  font-size: 24px;
  font-weight: 300;
  z-index: 6;
}
.close:hover {
  background-color: antiquewhite;
}
.close:active {
  transform: translateY(1px);
}
.bannerImage {
  width: 100%;
  margin-top: -50px;
  /* height: 100px; */
  object-fit: cover;
}

.title {
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 1px;
  max-width: 60%;
  text-align: center;
  margin: 10px 0 30px 0;
}
.content {
  display: flex;
  /* justify-content: space-between;
  align-items: flex-start; */
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow: auto;
  padding: 20px 0px;
  background-image: url('src/assets/images/algida_bg/desktop.png');
  background-position: 0% -40px;
  background-repeat: no-repeat;
  background-size: contain;
}
.cardsContainer {
  width: 100%;
  /* position: absolute; */
  display: flex;
  left: 0;
  top: 10vh;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  height: 70vh;
}
@media (min-width: 600px) {
  .container {
    width: 80%;
    max-height: 60vh;
    height: initial;
    border-radius: 20px;
    overflow: hidden;
  }
  .close {
    top: 20px;
  }
  .content {
    display: flex;
    /* justify-content: space-between;
    align-items: flex-start; */
    flex-direction: column;
    align-items: center;
    width: 100%;
    overflow: auto;
    padding: 20px 0px;
    background-image: url('src/assets/images/algida_bg/desktop.png');
    background-position: 0% -40px;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .title {
    color: #fff;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 1px;
    max-width: 80%;
    text-align: center;
    margin: 50px 0 30px 0;
  }
}

@media (min-width: 1070px) {
  .container {
    width: 600px;
    max-height: 80vh;
  }
}
