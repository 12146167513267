.modal {
  scroll-behavior: smooth;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100vh;
  background: none;
  box-shadow: none;
  background: var(--magnum-brown);
  overflow: auto;
  border-radius: 0;
  scroll-behavior: smooth;
  padding-bottom: 8vh;
  box-sizing: border-box;
  padding-top: 220px;
}

.close {
  color: white;
}

.header {
  /* position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 9vh;
  min-height: 9vh;
  background-color: var(--magnum-brown);
  font-size: 14px;
  color: white; */
}

.header .back {
  position: absolute;
  top: 0;
  left: 0%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20%;
  height: 100%;
}

.header .back img {
  width: 17px;
}

.coverImage {
  display: none;
  width: 100%;
}

.desktopTitle {
  display: none;
  color: var(--light-red);
  font-size: 17px;
  font-weight: 700;
}

.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  font-size: 13px;
  padding: 2vh 1vw 3vh 1vw;
  box-sizing: border-box;
  border-bottom: 1px solid lightgray;
}

.top .right {
  text-align: right;
}

.points {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  color: var(--magnum-gold);
}

.points img {
  width: 20px;
  height: auto;
  margin-right: 3px;
}

.enteredCode {
  color: var(--magnum-gold);
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
}

.raffleImage {
  width: 100%;
  margin-top: 2vh;
}

.directiveMessage {
  width: 80%;
  text-align: center;
  font-size: 14px;
  margin-bottom: 2vh;
  color: white;
}

.input {
  width: 95%;
  margin-bottom: 2vh;
  color: white
}

.phoneField {
  width: 95%;
  box-sizing: border-box;
}

.phoneInput {
  width: 100%;
  margin-bottom: 2vh;
  padding-left: 3%;
}

.phoneInput *,
.phoneInput input {
  font-weight: 500;
  font-size: 14px;
}

.checkbox {
  width: 92%;
  margin-bottom: 3vh;
  margin-top: 1vh;
}

.textarea {
  display: flex;
  flex-direction: column;
  width: 95%;
  font-weight: 700;
  font-size: 13px;
}

.label {
  width: 100%;
  color: white;
  opacity: 0.5;
  padding-left: 3%;
  font-size: 11px;
  font-weight: 700;
  box-sizing: border-box;
}

.textarea textarea {
  padding-left: 3%;
  padding-top: 10px;
  width: 100%;
  border: none;
  border-bottom: 1px solid lightgray;
  outline: none;
  height: 10vh;
}

.submitButton {
  width: 95%;
  margin: 2vh 0 4vh 0;
  color: var(--dark-grey, #2d2d2d);
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 1px;
  background: url('src/assets/images/magnum/magnumGold2.png'),
    lightgray -999.875px -156.729px / 393.333% 916.964% no-repeat;
}

.submitButton:hover {
  cursor: pointer;
  background: #e5c244;
}

.collapsbileContainer {
  width: 90%;
  height: 90%;
  margin-top: 10px;
  overflow-y: scroll;
}


.select {
  width: 95%;
  margin-bottom: 15px;
  color: white
}


.textModalContent {
  justify-content: flex-start;
  border-radius: 0;
  padding-right: 2px;
  padding-bottom: 20px;
}

.textModalContent .html {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 8vh 3% 3% 3%;
  overflow: auto;
}

.headerTitleCard {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  position: relative;
  background: var(--magnum-brown);
  box-shadow: 0px -10px 7px 0px rgba(0, 0, 0, 0.18);
  z-index: 10;
}

.headerTitle {
  text-align: center;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  background: var(
    --linear-2,
    linear-gradient(180deg, #ffc700 0%, #e5c244 100%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding-top: 10px;
  padding-bottom: 10px;
}

.headerBackImage {
  display: flex;
  top: 40px;
  position: absolute;
  z-index: 0;
  width: 220px;
  height: 220px;
}

@media (min-width: 600px) {
  .modal {
    align-items: flex-start;
    overflow: auto;
    padding: 20px 0 40px 0;
    box-sizing: border-box;
    background: rgba(56, 56, 56, 0.3);
  }

  .content {
    width: 100%;
    max-width: 500px;
    height: initial;
    overflow: initial;
    border-radius: 30px;
    padding-bottom: 5px;
  }

  .header {
    display: none;
  }

  .coverImage {
    display: block;
  }

  .desktopTitle {
    margin-top: -50px;
    display: block;
    margin-bottom: 20px;
  }

  .directiveMessage {
    margin: 10px 0 30px 0;
    font-size: 14px;
    font-weight: 500;
    color: white;
  }

  .top,
  .raffleImage,
  .checkbox,
  .submitButton,
  .input,
  .select,
  .phoneField {
    width: 80%;
  }

  .textModalContent {
    border-radius: 30px;
    padding-top: 40px;
  }

  .textModalContent .html {
    padding-top: 0;
  }
}

@media (min-width: 1100px) {
}
