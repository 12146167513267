.nav {
  width: 100%;
  height: 9vh;
  min-height: 58px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--algida-red);
  box-sizing: border-box;
}

.buttonContainerMagnum {
  width: 33%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #c4c4c4;
  text-align: center;
  font-family: Poppins;
  font-size: 13.576px;
  font-style: normal;
  font-weight: 700;
  line-height: 27.151px; /* 200% */
  letter-spacing: 1.358px;
  text-transform: uppercase;  
}

.magnumText {
  font-size: 10px;
}

.magnumAlgidaLogo {
  width: 54px;
  height: 40px;
}

.magnumLogo {
  width: 40px;
  height: 40px;
}

.magnumLogoMobile {
  width: 25px;
  height: 25px;
}

.navMagnum {
  width: 100%;
  height: 9vh;
  min-height: 58px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--magnum-brown);
  box-sizing: border-box;
}



.content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  /* padding: 0 2%; */
  box-sizing: border-box;
}

.left {
  width: 33%;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 33%;

}

.right {
  width: 33%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.logo {
  width: auto;
  height: 4vh;
}

.userFieldMagnum {
  background: linear-gradient(#e5c244, #c2a335, #a36d1b);
}

.userField {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: white;
  margin-left: 10px;
}

.userField:hover {
  color: rgb(216, 216, 216);
}

.userField img {
  /* background: var(--light-red); */
  padding: 10px;
  border-radius: 6px;
  transition: background 0.3s;
}

.userField:hover img {
  background-color: rgb(185, 0, 0);
}

.userField div {
  display: none;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 5px;
}

.userField span {
  font-size: 12px;
}

.userField b {
  width: 23vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
}

.loginButton {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--light-red);
  border: none;
  outline: none;
  color: white;
  padding: 10px;
  border-radius: 6px;
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.171);
  font-size: 12px;
}

.loginButton img {
  margin-right: 3px;
}

.buttonContainer {
  display: none;
  justify-content: center;
  align-items: flex-end;
  width: 33%;
  height: 70px;
}

.centerMagnum {
  display: none;
  justify-content: center;
  align-items: flex-end;
  width: 33%;
  height: 70px;
}

.navButton {
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;
  outline: none;
  color: white;
  height: 90px;
  height: 90px;
  border-radius: 50% 50% 0 0;
  box-sizing: border-box;
  font-size: 15px;
  margin: 10px 2px -35px 2px;
  padding: 0 2% 15px 2%;
  /* z-index: 9; */
}

.navButton::after {
  content: ' ';
  display: block;
  width: 4px;
  height: 4px;
  border-radius: 50%;
}

.navButton:hover {
  cursor: pointer;
  background: rgba(255, 255, 255, 0.4);
}

.activeButton {
  background: rgba(255, 255, 255, 0.3);
}

.activeButton::after {
  background: white;
}

.title {
  font-weight: 500;
  color: white;
  letter-spacing: 0.2px;
  font-size: 14px;
}

.backArrow {
  width: 10px;
  height: 10px;
  border-bottom: 2px solid white;
  border-left: 2px solid white;
  transform: rotate(45deg);
  margin-left: 8px;
}

.hideOnMobile {
  display: none;
}

.pointerShadowMagnum {
  background: rgba(240, 61, 48, 0.2);
  box-shadow: 4px 4px 4px 0px rgba(77, 18, 14, 1) inset;
}

.pointerShadow {
  box-shadow: inset 2px 0 8px rgba(0, 0, 0, 0.2);
}

.pointField {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2.5vw 3.5vw;
  color: white;
  font-size: 12px;
  border-radius: 25px;
  font-weight: bold;
  letter-spacing: 0.1px;
}

.pointField img {
  width: 5.5vw;
  margin-right: 5px;
}

.desktopPointField {
  display: none;
  margin-right: 10px;
}

@media (min-width: 600px) {
  .pointField {
    display: none;
    padding: 10px;
    font-size: 15px;
  }

  .magnumText {
    font-size: 12px;
  }
}

@media (min-width: 600px) {
  .magnumAlgidaCard {
    display: flex;
    height: 66px;
    width: 60px;
    padding: 0px 25px 0px 25px;
    border-radius: 99px 99px 0px 0px;
    background-color: var(--algida-red);
    justify-content: center;
    align-items: center;
  }

  .nav {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    height: 70px;
  }

  .content {
    justify-content: space-between;
    max-width: var(--max-width);
    padding: 0 2%;
  }

  .center {
    width: 33%;
    justify-content: flex-start;
    order: 1;
  }

  .buttonContainer {
    display: flex;
    order: 2;
  }

  .centerMagnum {
    display: flex;
    order: 1;
  }

  .buttonContainerMagnum {
    display: flex;
    order: 2;
  }

  .backButton {
    display: none;
  }

  .right {
    width: initial;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    order: 3;
    background-color: antiquewhite !important;
  }

  .left {
    width: 33%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    order: 4;
  }

  .pointField img {
    width: 20px;
  }

  .desktopPointField {
    display: flex;
  }

  .navButton {
    display: flex;
  }

  .hideOnMobile {
    display: flex;
  }

  .userField div b {
    width: 100px;
  }

  .title {
    display: none;
  }
}

@media (min-width: 700px) {
  .navButton {
    margin: 10px 8px -35px 8px;
    padding: 0 25px 15px 25px;
  }
}

@media (min-width: 930px) {
  .userField div {
    display: flex;
  }
}

@media (min-width: 1100px) {
  .logo {
    margin-top: 10px;
    height: 38px;
  }

  .magnumText {
    font-size: 14px;
  }
}

@media (min-width: 1180px) {
  .content {
    padding: 0;
  }
}
