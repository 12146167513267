.content {
  justify-content: flex-start;
  border-radius: 0;
  padding: 7vh 3% 6vh 3%;
  box-sizing: border-box;
  overflow: auto;
}

.close {
  /* color: white; */
}

.coverImage {
  display: none;
  width: 100%;
}

.title {
  font-size: 18px;
  font-weight: bold;
  color: var(--light-red);
  margin-bottom: 2vh;
}

.html {
  max-width: 90vw;
}

.html p a {
  word-break: break-all;
}

@media (min-width: 600px) {
  .modal {
    align-items: flex-start;
    overflow: auto;
    padding: 20px 0;
  }

  .content {
    border-radius: 30px;
    padding: 0;
    height: initial;
    max-width: 570px;
    overflow: initial;
  }

  .close {
    top: 1px;
    right: 11px;
    font-weight: 500;
    font-size: 35px;
    color: white;
  }

  .coverImage {
    display: block;
  }

  .title {
    margin-top: -30px;
  }

  .html {
    padding: 0 20px;
    box-sizing: border-box;
  }
}

@media (min-width: 1100px) {
}
