.modal {
  scroll-behavior: smooth;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100vh;
  background: none;
  box-shadow: none;
  background: white;
  overflow: auto;
  border-radius: 0;
  scroll-behavior: smooth;
  padding-bottom: 8vh;
  box-sizing: border-box;
}

.close {
  color: white;
}

.header {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 9vh;
  min-height: 9vh;
  background-color: var(--algida-red);
  font-size: 14px;
  color: white;
}

.header .back {
  position: absolute;
  top: 0;
  left: 0%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20%;
  height: 100%;
}

.header .back img {
  width: 17px;
}

.coverImage {
  display: none;
  width: 100%;
}

.desktopTitle {
  display: none;
  color: var(--light-red);
  font-size: 17px;
  font-weight: 700;
}

.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  font-size: 13px;
  padding: 2vh 1vw 3vh 1vw;
  box-sizing: border-box;
  border-bottom: 1px solid lightgray;
}

.top .right {
  text-align: right;
}

.points {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  color: var(--light-red);
}

.points img {
  width: 20px;
  height: auto;
  margin-right: 3px;
}

.enteredCode {
  color: var(--light-red);
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
}

.raffleImage {
  width: 100%;
  margin-top: 2vh;
}

.directiveMessage {
  width: 80%;
  text-align: center;
  font-size: 14px;
  margin-bottom: 2vh;
}

.input {
  width: 95%;
  margin-bottom: 2vh;
}

.phoneField {
  width: 95%;
  box-sizing: border-box;
}

.phoneInput {
  width: 100%;
  margin-bottom: 2vh;
  padding-left: 3%;
}

.phoneInput *,
.phoneInput input {
  font-weight: 500;
  font-size: 14px;
}

.checkbox {
  width: 92%;
  margin-bottom: 3vh;
  margin-top: 1vh;
}

.textarea {
  display: flex;
  flex-direction: column;
  width: 95%;
  font-weight: 700;
  font-size: 13px;
}

.label {
  width: 100%;
  color: var(--black);
  opacity: 0.5;
  padding-left: 3%;
  font-size: 11px;
  font-weight: 700;
  box-sizing: border-box;
}

.textarea textarea {
  padding-left: 3%;
  padding-top: 10px;
  width: 100%;
  border: none;
  border-bottom: 1px solid lightgray;
  outline: none;
  height: 10vh;
}

.submitButton {
  width: 95%;
  margin: 2vh 0 4vh 0;
}

.faqClass {
  z-index: 20;
}

.faqModal {
  justify-content: flex-start;
  padding-right: 2px;
  padding-bottom: 20px;
  padding-top: 40px;
  height: 70%; 
}

.collapsbileContainer {
  width: 90%;
  height: 90%;
  margin-top: 10px;
  overflow-y: scroll;
}

.faqTitle {
  font-size: 16px;
  font-weight: bold;
}

.select {
  width: 95%;
  margin-bottom: 15px;
}

.textModal {
}

.textModalContent {
  justify-content: flex-start;
  border-radius: 0;
  padding-right: 2px;
  padding-bottom: 20px;
}


.textModalContent .html {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 8vh 3% 3% 3%;
  overflow: auto;
}

@media (min-width: 600px) {
  .modal {
    align-items: flex-start;
    overflow: auto;
    padding: 20px 0 40px 0;
    box-sizing: border-box;
    background: rgba(56, 56, 56, 0.3);
  }

  .content {
    width: 100%;
    max-width: 500px;
    height: initial;
    overflow: initial;
    border-radius: 30px;
    padding-bottom: 5px;
  }

  .header {
    display: none;
  }

  .coverImage {
    display: block;
  }

  .desktopTitle {
    margin-top: -50px;
    display: block;
    margin-bottom: 20px;
  }

  .directiveMessage {
    margin: 10px 0 30px 0;
    font-size: 14px;
    font-weight: 500;
  }

  .top,
  .raffleImage,
  .checkbox,
  .submitButton,
  .input,
  .select,
  .phoneField {
    width: 80%;
  }

  .textModalContent {
    border-radius: 30px;
    padding-top: 40px;
  }

  .textModalContent .html {
    padding-top: 0;
  }
}

@media (min-width: 1100px) {
}
