.container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid lightgray;
  padding: 5px;
  box-sizing: border-box;
}

.prefix {
  font-weight: 700;
  color: black;
  border-right: 1px solid var(--background-blue);
  padding-right: 8px;
  box-sizing: border-box;
  font-size: 16px;
}

.container input {
  outline: none;
  width: 100%;
  height: 100%;
  border: 0;
  padding: 5px 0 5px 8px;
  box-sizing: border-box;
  color: var(--black);
  font-size: 16px;
  font-weight: 700;
  box-sizing: border-box;
}
