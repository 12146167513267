.modal {
  overflow: auto;
}

.content {
  width: 90%;
  height: initial;
  justify-content: flex-start;
  overflow: hidden;
}

.close {
  color: white;
}

.coverImage {
  width: 100%;
}

.title {
  color: var(--algida-red);
  font-weight: 700;
  font-size: 18px;
}

.subtitle {
  width: 70%;
  font-weight: 500;
  font-size: 13px;
  margin-top: 2vh;
  margin-bottom: 3vh;
  text-align: center;
}

.button {
  width: 90%;
  margin-top: 10px;
}

.bottom {
  width: 100%;
  height: 100%;
  text-align: center;
  margin-top: 4vh;
  padding: 2vh 5% 5vh 5%;
  box-sizing: border-box;
  background-color: var(--background-blue);
}

.bottomText {
  font-weight: 600;
  font-size: 11px;
  color: var(--night-grey);
}

.productField {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2.5vh;
}

.product {
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.2px;
}

.product div {
  width: 24vw;
  height: 24vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  box-sizing: border-box;
  border-radius: 15px;
  margin-bottom: 3px;
  box-shadow: 1px 1px 8px rgba(30, 30, 60, 0.15);
}

.product img {
  width: 100%;
}

@media (min-width: 600px) {
  .content {
    width: 520px;
    height: initial;
    padding-bottom: 5%;
  }

  .button {
    width: 60%;
  }

  .product div {
    width: 150px;
    height: 150px;
  }
}

@media (min-width: 1100px) {
  .content {
    width: 30vw;
    max-width: 500px;
    width: 40%;
    padding-bottom: 0;
  }

  .bottom {
    padding-bottom: 3vh;
  }

  .productField {
    justify-content: center;
  }

  .product:nth-child(2) {
    margin: 0 20px;
  }

  .product div {
    width: 100px;
    height: 100px;
  }
}

@media (max-height: 650px) {
  .modal {
    align-items: flex-start;
  }

  .content {
    margin: 20px 0;
  }
}
