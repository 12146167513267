.page {
  display: flex;
  width: 100%;
  height: 100vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f0eef3;
}
.animation {
  margin: 10px;
  width: 30%;
  min-width: 300px;
}

.message {
  width: 60%;
  text-align: center;
  font-size: 14px;
}

.support {
  text-align: center;
  font-size: 14px;
}
