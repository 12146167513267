.container {
  width: 100%;
  min-height: 91vh;
  background: white;
  background: url(src/assets/images/magnum/magnumBack.png) center / cover
    no-repeat;
}

.magnumHeaderCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

.magnumHeaderTextTwo {
  color: var(--magnum-sars, #fbf49f);
  text-align: center;
  text-shadow: 0px 3.3388428688049316px 3.3388428688049316px 0px
      rgba(0, 0, 0, 0.25),
    7.512396335601807px 1.6694214344024658px 29.214874267578125px 0px #000;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 24.927px; /* 114.063% */
}

.faqContainer {
  position: relative;
  display: flex;
  justify-content: center;
  padding: 20px 0px 20px 0px;
  width: 100%;
  max-width: 570px;
  max-height: 610px;
  height: 610px;
  border-radius: 25px;
  margin-top: 10px;
  background: white;
}

.leftBackButton {
  top: 30px;
  left: 30px;
  position: absolute;
  border: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 99px;
  background: white;
  cursor: pointer;
}

.leftBackButton:active {
  top: 30px;
  left: 30px;
  position: absolute;
  border: 0px;
  background: rgb(238, 238, 238);
}

.leftBack {
  width: 30px;
  height: 30px;
}

.listContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  padding-top: 20px;
}

.listTitle {
  color: var(--night-grey, #5d6673);
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
}

.listCard {
  width: 100%;
  margin-top: 10px;
  overflow-y: scroll;
}


@media (min-width: 600px) {
  .container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background: url(src/assets/images/magnum/magnumBack.png) center /
      cover no-repeat;
  }
}
