.content {
  position: absolute;
  bottom: -200vh;
  bottom: 0;
  left: 0;
  width: 100%;
  height: initial;
  justify-content: flex-start;
  overflow: hidden;
  border-radius: 20px 20px 0 0;
  padding-bottom: 2vh;
  transition: bottom 0.3s;
}

.close {
  color: black;
}

.coverImage {
  display: none;
  width: 100%;
}

.productImage {
  width: 30vw;
  height: 30vw;
  box-shadow: 2px 2px 8px rgba(100, 100, 100, 0.2);
  border-radius: 20px;
  margin-top: 2vh;
  padding: 5px;
  box-sizing: border-box;
}

.name {
  color: var(--night-grey);
  margin-top: 2vh;
}

.copyField {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--background-blue);
  width: 90%;
  height: 65px;
  min-height: 60px;
  border-radius: 12px;
  margin: 3vh 0 1vh 0;
  transition: background 0.3s;
}

.copyLeft {
  width: 77%;
  padding-left: 8%;
  box-sizing: border-box;
  letter-spacing: 1px;
  word-break: break-all;
  font-size: 17px;
  text-align: center;
  font-weight: 700;
}

.copyButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 23%;
  height: 100%;
  border-radius: 0 12px 12px 0;
  border: none;
  color: white;
  font-size: 11px;
  outline: none;
  background: var(--lighgray);
}

.copyButton span {
  margin-top: 3px;
}

.copySuccess {
  color: green;
  font-size: 13px;
  font-weight: 600;
}

.howToButton {
  width: 85%;
  height: 55px;
  margin-top: 3vh;
  font-weight: 700;
}

.howToButton img {
  width: 16px;
  margin-left: 5px;
}

.bottom {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  margin-top: 4vh;
  color: var(--night-grey);
  font-size: 12px;
}

.bottomText {
  text-align: center;
  font-weight: 400;
}

.howToDesktop {
  display: none;
  width: 70%;
  /* box-shadow: 1px 1px 8px rgba(100, 100, 100, 0.2); */
  border-bottom: 1px solid #f5f5f5;
  border-radius: 8px;
  margin-top: 10px;
}

.howToDesktop .top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 50px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  padding: 0 10px;
  box-sizing: border-box;
}

.howToDesktop .top:hover {
  background: whitesmoke;
}

.howToDesktop .top img {
  width: 35px;
}

.dropdownContent {
  width: 100%;
  padding: 5px 0 5px 10px;
  box-sizing: border-box;
  font-size: 14px;
  height: 10vh;
  min-height: 100px;
  overflow: auto;
}

.dropdownContent::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(221, 221, 221, 0.5);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.dropdownContent::-webkit-scrollbar {
  width: 7px;
  background-color: #f5f5f5;
}

.dropdownContent::-webkit-scrollbar-thumb {
  border-radius: 8px;
  -webkit-box-shadow: inset 0 0 6px rgba(121, 121, 121, 0.5);
  background-color: rgb(85, 78, 78);
}

.button {
  width: 85%;
  margin-top: 10px;
}

.qrField {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 15px;
}

.qrCode {
  font-size: 13px;
  font-weight: 500;
}

.redeemDescription {
  width: 80%;
  text-align: center;
  margin-top: 5px;
  font-size: 14px;
  opacity: 0.8;
}

.shareField {
  width: 80%;
  margin-top: 3%;
}

.shareText {
  color: var(--night-grey);
  font-size: 14px;
}

.shareButtonField {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.hiddenCodeImage {
  width: 100%;
  height: 48px;
  object-fit: cover;
  border-radius: 5px;
  margin-bottom: 3%;
  margin-top: 1%;
  cursor: pointer;
}

.shareButton {
  width: 100%;
  cursor: pointer;
}

.hiddenCodeImage:active,
.shareButton:active {
  opacity: 0.7;
}

.shareButton img {
  display: none;
  width: 22px;
  height: 22px;
}

@media (min-width: 600px) {
  .content {
    position: relative;
    width: 80%;
    height: initial;
    padding-bottom: 30px;
    padding-top: 0;
    border-radius: 30px;
  }

  .coverImage {
    display: initial;
  }

  .close {
    top: 0;
    color: white;
    font-weight: 500;
    font-size: 35px;
  }

  .productImage {
    margin-top: -8%;
    width: 120px;
    height: 120px;
  }

  .howToButton {
    display: none;
  }

  .howToDesktop {
    display: initial;
  }

  .bottom {
    margin-top: 30px;
  }

  .shareField {
    width: 68%;
  }

  .shareButtonField {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 3%;
  }

  .hiddenCodeImage {
    width: calc(100% - 70px) !important;
    height: 60px;
    margin-bottom: 0;
  }

  .shareButton {
    width: 60px;
    height: 60px;
    margin-top: 5px;
    border-radius: 5px;
  }

  .shareButton span {
    display: none;
  }

  .shareButton img {
    display: initial;
  }
}

@media (min-width: 1100px) {
  .content {
    width: 30vw;
    max-width: 500px;
    width: 40%;
  }

  .copyField:hover {
    cursor: pointer;
    background-color: rgb(224, 224, 224);
  }

  .shareButton:hover {
    opacity: 0.5;
  }

  .hiddenCodeImage:hover {
    opacity: 0.8;
  }
}
