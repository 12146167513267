.button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 45px;
  min-height: 45px;
  border: none;
  border-radius: 40px;
  font-weight: 600;
  font-size: 13px;
  padding: 7px 14px;
  background: var(--algida-red);
  color: white;
  outline: none;
}

.dark {
  background: rgb(26, 26, 26);
}

.secondary {
  background-color: white;
  color: var(--algida-red);
  cursor: pointer;
  font-weight: bold;
  border: 1px solid var(--algida-red);
}

.textButton {
  background: none;
  color: var(--algida-red);
  font-weight: bold;
}

.disabled {
  background-color: var(--night-grey) !important;
  cursor: not-allowed;
}

@media (min-width: 1050px) {
  .button:hover {
    background: rgb(243, 0, 0);
  }
  .button:active {
    background: rgb(224, 0, 0);
  }

  .dark:hover {
    background: rgb(22, 22, 22);
  }

  .dark:active {
    background: rgb(41, 41, 41);
  }

  .secondary:hover {
    background: white;
    color: rgb(203, 0, 0);
  }

  .secondary:active {
    background: white;
    color: rgb(174, 0, 0);
  }

  .textButton:hover {
    background: white;
    color: rgb(174, 0, 0);
    cursor: pointer;
  }

  .disabled:hover,
  .disabled:active {
    background-color: gray;
  }
}
