.container,
.stockOutContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 48%;
  height: 260px;
  border: 1px solid lightgray;
  margin-top: 2vh;
  border-radius: 16px;
  box-sizing: border-box;
  background: white;
  cursor: pointer;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
}

.stockOutContainer {
  cursor: default;
}

.stockOutContainer:hover,
.container:hover {
  background-color: whitesmoke;
}

.image {
  width: 60%;
  margin-top: 7%;
  height: auto;
  border-radius: 10px;
}

.name {
  display: block;
  width: 90%;
  overflow: hidden;
  text-align: center;
  margin-top: 2vh;
  font-size: 12px;
  color: var(--text-color);
  text-align: center;
}

.amount {
  display: block;
  width: 100%;
  text-align: center;
  margin-top: 1.5vh;
  color: var(--darkgreen);
  font-size: 12px;
}

.stockOutText {
  display: inline-block;
  width: 100%;
  font-size: 13px;
  color: white;
  text-align: center;
  margin-top: 1.5vh;
}

.bottom {
  /* position: absolute;
  bottom: 0;
  left: 0; */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 15%;
  padding-bottom: 10px;
  background: var(--algida-red);
  color: white;
  font-size: 14px;
  border-radius: 0 0 16px 16px;
  text-align: center;
}

.stockOutContainer .bottom {
  background: #5d6673;
}

@media (min-width: 600px) {
  .container,
  .stockOutContainer {
    width: 32%;
    height: 280px;
    margin-left: 1%;
  }
}

@media (min-width: 1070px) {
  .container,
  .stockOutContainer {
    height: 280px;
  }
}
