.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0;
  padding-top: 5vh;
  padding-bottom: 5vh;
  box-sizing: border-box;
  transition: opacity 0.3s;
  background: rgba(255, 255, 255, 0.747)
    url(src/assets/images/code-enter/background.png) center / cover
    no-repeat;
  overflow: auto;
  z-index: 5;
}

.modalContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  justify-content: flex-start;
  background: none;
  box-shadow: none;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
  height: initial;
  background: white;
  border-radius: 20px;
  padding: 4vh 5% 2vh 5%;
  box-sizing: border-box;
}

.close {
  display: none;
}

.coverImage {
  display: none;
  width: 100%;
}

.surprise {
  color: #000;
  font-size: 17px;
  font-weight: 700;
}

.productField {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  margin-top: 3vh;
}

.and {
  color: var(--night-grey);
  font-size: 16px;
  font-weight: 600;
  margin: 19% 5px 0 5px;
}

.product {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 30vw;
  background: white;
  font-weight: 600;
  padding: 3%;
}

.product .imageField {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30vw;
  height: 30vw;
  box-sizing: border-box;
  box-shadow: 2px 2px 8px rgba(100, 100, 100, 0.3);
  border-radius: 20px;
}

.product .imageField img {
  width: 60%;
  height: 60%;
  object-fit: contain;
}

.productName {
  margin-top: 2vh;
}

.winText {
  color: var(--light-red);
}

.totalPoint {
  font-weight: 600;
  margin-top: 3vh;
  color: var(--night-grey);
}

.firstButton {
  margin-top: 3vh;
  margin-bottom: 1vh;
}

.raffleContent {
  margin-top: 3vh;
  padding: 2% 2% 3% 2%;
}

.raffleContent img {
  width: 100%;
  border-radius: 20px;
}

.raffleTitle {
  font-size: 14px;
  margin-top: 1.5vh;
  margin-bottom: 3vh;
  text-align: center;
}

.raffleButton {
  width: 90%;
}

.raffleCancelButton {
  color: var(--night-grey);
}

@media (min-width: 600px) {
  .modal {
    background: rgba(56, 56, 56, 0.3);
    overflow: auto;
    padding: 0;
  }

  .modalContent {
    background: white;
    max-width: 470px;
    /* max-height: 98vh; */
    border-radius: 30px;
    padding-bottom: 10px;
  }

  .content {
    position: relative;
    width: 100%;
    height: initial;
    padding: 0 0 10px 0;
    border-radius: 0;
    background: none;
    margin: 0;
  }

  .productField {
    margin-top: 2vh;
  }

  .and {
    margin-top: 10%;
  }

  .coverImage {
    display: block;
  }

  .surprise {
    margin-top: -60px;
  }

  .product {
    width: initial;
    font-size: 14px;
  }

  .productName {
    margin-top: 9px;
  }

  .product .imageField {
    margin-top: -8%;
    width: 100px;
    height: 100px;
  }

  .totalPoint {
    margin-top: 30px;
  }

  .raffleContent img {
    width: 75%;
  }

  .raffleTitle {
    margin: 1% 0 3vh 0;
  }

  .firstButton,
  .raffleButton {
    width: 55%;
  }

  .raffleContent {
    margin-top: 0;
  }

  .raffleCancelButton {
    margin-top: 5px;
    font-size: 12px;
  }
}
.giftsField {
  width: 100%;
  overflow-y: scroll;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.giftContainer {
  width: 80%;
  margin: 10px;
  height: 70px;
  border-radius: 8px;
  border: 1px solid red;
  display: flex;
  padding: 5px 10px;
  align-items: center;
  justify-content: space-between;
}
.giftImage {
  width: 60px;
  height: 60px;
  object-fit: contain;
}
.selectGift {
  display: flex;
  cursor: pointer;
  width: 60px;
  height: 30px;
  border-radius: 8px;
  background-color: var(--algida-red);
  justify-content: center;
  align-items: center;
}
.selectGift:hover {
  background-color: #ef0000;
}
.selectGiftText {
  color: #fff;
}
@media (min-width: 1100px) {
  .copyField:hover {
    cursor: pointer;
    background-color: rgb(224, 224, 224);
  }
}
