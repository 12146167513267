.container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100vw;
  height: 100vh;
  max-width: initial;
  box-sizing: border-box;
  background: rgb(248, 248, 248);
  padding: 0;
  border-radius: 0;
}

.close {
  display: flex !important;
  justify-content: center;
  align-items: center;
  /* background-color: whitesmoke; */
  color: black;
  width: 25px;
  height: 25px;
  border-radius: 8px;
  font-size: 24px;
  font-weight: 300;
  z-index: 6;
}

.bannerImage {
  width: 100%;
}

.loading {
  position: initial;
  height: 30vh;
}

.top {
  width: 100%;
  background: white;
  border-radius: 0 0 10px 10px;
  /* background: var(--algida-red); */
  padding: 5px 4% 20px 4%;
  /* padding: 5px 4% 10px 4%; */
  box-sizing: border-box;
  color: black;
  border-bottom: 1px solid lightgray;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 5;
}

.title {
  width: 100%;
  margin-bottom: 10px;
  text-align: center;
  /* font-weight: 400; */
  /* font-size: 15px; */
}

.subtitle {
  /* display: none; */
  font-size: 14px;
  color: gray;
  width: 100%;
  margin-bottom: 10px;
  text-align: center;
}

.pointField {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: var(--gray);
  font-size: 14px;
  padding: 6px 6px 6px 10px;
  box-sizing: border-box;
  margin-top: 3vh;
  border-radius: 8px;
}

.pointText {
  font-weight: 700;
  font-size: 16px;
}

.pointBox {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 55px;
  height: 40px;
  color: white;
  font-family: Gotham;
  font-weight: 600;
  border-radius: 8px;
  background-color: var(--darkgreen);
}

.content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  overflow: auto;
  padding: 10px 4%;
  box-sizing: border-box;
  margin-top: -4px;
}

@media (min-width: 600px) {
  .container {
    width: 80%;
    max-height: 60vh;
    height: initial;
    border-radius: 20px;
    overflow: hidden;
  }

  .close {
    top: 20px;
  }

  .top {
    border-radius: 20px;
  }

  .content {
    margin-top: -15px;
    padding-top: 20px;
    justify-content: flex-start;
    padding-bottom: 20px;
  }
}

@media (min-width: 1070px) {
  .container {
    width: 600px;
    max-height: 80vh;
  }
}
