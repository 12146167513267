.card {
  position: fixed;
  bottom: 2%;
  right: 1%;
  padding: 3px 10px;
  border-radius: 5px;
  background-color: rgba(204, 204, 204, 0.20);
  font-size: 13px;
  color: gray;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

/* mobile device media */
@media (max-width: 768px) {
  .card {
    bottom: 10vh;
    right: 0%;
    border-radius: 0;
    border-top-left-radius: 5px;
    box-shadow: none;
    background-color: #fff;
  }
}