:root {
  --dark-grey: #2d2d2d;
  --night-grey: #5d6673;
  --background-blue: #f6f8fa;
  --algida-red: #da291c;
  --light-red: #f03d30;
  --light-red-hover: #b42015;
  --background: #dadada;
  --max-width: 1170px;
  --magnum-brown: #36160D;
  --magnum-dark: #17150B;
  --magnum-gold: #FFC700;
}

html {
  scroll-behavior: smooth;
}
@font-face {
  font-family: OpenSansRegular;
  src: url('./assets/fonts/OpenSans-Regular.ttf') format('opentype');
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: OpenSansSemiBold;
  src: url('./assets/fonts/OpenSans-SemiBold.ttf') format('opentype');
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: OpenSansBold;
  src: url('./assets/fonts/OpenSans-Bold.ttf') format('opentype');
  font-style: normal;
  font-display: swap;
}
/* @font-face {
  font-family: 'Poppins';
  font-weight: 400;
  src: url("./assets/fonts/Poppins-SemiBold.ttf") format("opentype"); 
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  font-weight: 500;
  src: url("./assets/fonts/Poppins-Medium.ttf") format("opentype"); 
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  font-weight: 600;
  src: url("./assets/fonts/Poppins-Bold.ttf") format("opentype"); 
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  font-weight: 700;
  src: url("./assets/fonts/Poppins-ExtraBold.ttf") format("opentype"); 
  font-display: swap;
} */

button,
input,
select {
  font-family: 'Poppins';
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  font-family: Poppins, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-blue);
  color: var(--dark-grey);
}

input {
  font-family: Poppins;
  font-weight: 500;
}

a,
.link {
  color: var(--light-red);
  text-decoration: none;
}

/* a:hover{
  color: rgb(170, 0, 0);
} */

#root {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(221, 221, 221, 0.5);
  border-radius: 10px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(121, 121, 121, 0.5);
  background-color: rgb(85, 78, 78);
}

.gm-control-active,
.gmnoprint {
  display: none;
}

@media (max-width: 600px) {
  #ot-sdk-btn.ot-sdk-show-settings {
    width: 40px !important;
    height: 40px !important;
    background-repeat: no-repeat !important;
    bottom: 13vh !important;
  }
}
