.modal {
  overflow: auto;
}

.close {
  top: 0;
  color: white;
  font-size: 35px;
  margin-right: 5px;
}

.content {
  align-items: flex-start;
  justify-content: flex-start;
  border-radius: 0;
}

.background {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 44vw;
  color: white;
  padding: 0 17%;
  box-sizing: border-box;
  text-align: center;
  font-weight: 700;
  letter-spacing: 2px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom center;
}

.vendorList {
  width: 100%;
  padding: 0 5%;
  box-sizing: border-box;
  margin-top: -10vw;
}

.vendor {
  width: 100%;
  margin-bottom: 5%;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
  border-radius: 18px;
  overflow: hidden;
}

.vendor img {
  width: 20%;
  margin: 2vh 0;
}

.vendorTop {
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 0 4%;
  box-sizing: border-box;
}

.vendorText {
  color: var(--night-grey);
  font-size: 13px;
}

.vendorContent {
  margin-left: 5%;
}

.vendorBottom {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px 4%;
  box-sizing: border-box;
  background-color: var(--algida-red);
  color: white;
  font-size: 12px;
}

.disabledVendor .vendorBottom {
  background-color: var(--night-grey);
}

.closedField {
  display: flex;
  justify-content: center;
  align-items: center;
}

.dot {
  width: 8px;
  height: 8px;
  background-color: red;
  border-radius: 50%;
  margin-right: 5px;
}

@media (min-width: 600px) {
  .modal {
    display: block;
    padding: 5% 0;
    box-sizing: border-box;
  }

  .content {
    width: 520px;
    height: initial;
    padding-bottom: 5%;
    justify-content: flex-start;
    align-items: flex-start;
    border-radius: 16px;
    overflow: hidden;
    margin: auto;
  }

  .background {
    height: 230px;
  }

  .button {
    width: 60%;
  }

  .product div {
    width: 150px;
    height: 150px;
  }
}

@media (min-width: 1024px) {
  .content {
    width: 450px;
    padding-bottom: 0;
    height: initial;
  }

  .background {
    margin-top: -1px;
    height: 200px;
  }

  .vendorList {
    margin-top: -50px;
  }

  .vendor:hover {
    background: rgba(245, 245, 245, 0.9);
    cursor: pointer;
  }
}

@media (max-height: 650px) {
  .modal {
    align-items: flex-start;
  }

  .content {
    margin: 20px 0;
  }
}
