.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0;
  padding-top: 4vh;
  padding-bottom: 5vh;
  box-sizing: border-box;
  /* transition: opacity 0.3s; */
  background: rgba(255, 255, 255, 0.747)
    url(src/assets/images/code-enter/background.png) center / cover no-repeat;
  /* overflow: scroll; */
  z-index: 5;
}

.modalContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  justify-content: flex-start;
  background: none;
  box-shadow: none;
  overflow: scroll;
  scrollbar-width: 1;
  max-height: 97%;
}

::-webkit-scrollbar {
  display: none;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
  height: initial;
  background: white;
  border-radius: 20px;
  padding: 2vh 5%;
  box-sizing: border-box;
}

.close {
  display: none;
}

.coverImage {
  display: none;
  width: 100%;
  height: 190px;
}

.surprise {
  color: var(--light-red);
  font-size: 17px;
  font-weight: 700;
  text-align: center;
}

.limitMessage {
  color: var(--dark-grey);
  font-weight: 500;
  font-size: 14px;
}

.productField {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  margin-top: 3vh;
}

.and {
  color: var(--night-grey);
  font-size: 16px;
  font-weight: 600;
  margin: 19% 5px 0 5px;
}

.product {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  background: white;
  font-weight: 600;
  padding: 3%;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: #f9f1f0;
}

.product .imageFieldContainer {
  display: flex;
  gap: 10px;
}

.product .imageField {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30vw;
  height: 30vw;
  box-sizing: border-box;
  box-shadow: 2px 2px 8px rgba(100, 100, 100, 0.3);
  border-radius: 20px;
}

.product .imageField img {
  width: 60%;
  height: 60%;
  object-fit: contain;
}

.productName {
  margin-top: 2vh;
  color: var(--algida-red);
  font-weight: 700;
  text-align: center;
}

.winText {
  color: var(--dark-grey);
}

.totalPoint {
  font-weight: 600;
  margin-top: 3vh;
  color: var(--night-grey);
}

.firstButton {
  margin-top: 2vh;
  margin-bottom: 1vh;
}

.raffleContent {
  margin-top: 3vh;
  padding: 2% 2% 3% 2%;
}

.raffleContent img {
  width: 100%;
  border-radius: 20px;
}

.raffleTitle {
  font-size: 14px;
  margin-top: 1.5vh;
  margin-bottom: 3vh;
  text-align: center;
}

.raffleButton {
  width: 90%;
}

.raffleCancelButton {
  color: var(--night-grey);
}
.wheel {
  width: 100vw;
  height: 100vh;
}

.infoMessage {
  margin-top: 1vh;
  font-size: 12px;
  text-align: center;
  line-height: 20px;
  font-weight: 600;
  font-family: 'Poppins';
}

.messageCard {
  font-weight: 600;
  width: 90%;
  font-size: 14px;
  margin-bottom: 1vh;
  text-align: center;
  color: var(--night-grey);
}

.topBoxContainer {
  background-color: #fece72;
  width: 70%;
  text-align: center;
  min-height: 300px;
  border-radius: 20px;
  padding-top: 30px;
  padding-bottom: 20px;
  position: relative;
  z-index: 0;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.topGiftNameCard {
  font-size: 10px;
  margin-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.topMedalImage {
  position: absolute;
  top: 0px;
  right: 20px;
  width: 80%;
  z-index: -1;
}

.topBoxCard {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.topBoxTitle {
  font-size: 18px;
  font-weight: 600;
  color: #5f461a;
}

.topBoxTitleBig {
  font-size: 24px;
  font-weight: bold;
  text-transform: uppercase;
  color: var(--algida-red);
}

.topMessageText {
  font-size: 14px;
  color: #8a631e;
}

.topGiftImage {
  width: 100px;
  height: 100px;
  border-radius: 20px;
  margin-top: 15px;
}

.topCancelButton {
  width: 70%;
  margin-top: 1vh;
  background-color: var(--dark-grey);
  color: white;
}

.topCancelButton:hover {
  background-color: var(--dark-grey);
  color: white;
}

.bottomBoxContainer {
  display: flex;
  flex-direction: column;
  background-color: rgba(243, 243, 243, 0.85);
  width: 70%;
  text-align: center;
  min-height: 200px;
  border-radius: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  position: relative;
  z-index: 0;
  align-items: center;
  justify-content: center;
  align-content: center;
  margin-top: 20px;
}

.bottomBoxTitle {
  font-size: 16px;
  font-weight: bold;
  color: var(--algida-red);
}

.bottomBoxMessage {
  margin-top: 10px;
  font-size: 14px;
  color: #2d2d2d;
}

.bottomCancelButton {
  width: 70%;
  margin-top: 1vh;
  background-color: var(--dark-grey);
  color: white;
}

.bottomCancelButton:hover {
  background-color: var(--dark-grey);
  color: white;
}

@media (min-width: 600px) {
  .modal {
    background: rgba(56, 56, 56, 0.3);
    overflow: auto;
    padding: 0;
  }

  .modalContent {
    background: white;
    max-width: 470px;
    border-radius: 30px;
    padding-bottom: 10px;
  }

  .content {
    position: relative;
    width: 100%;
    height: initial;
    padding: 0 0 10px 0;
    border-radius: 0;
    background: none;
    margin: 0;
  }

  .productField {
    margin-top: 2vh;
  }

  .and {
    margin-top: 10%;
  }

  .coverImage {
    display: block;
  }

  .surprise {
    margin-top: -60px;
  }

  .product {
    width: initial;
    font-size: 14px;
  }

  .productName {
    margin-top: 9px;
  }

  .product .imageField {
    margin-top: -8%;
    width: 100px;
    height: 100px;
  }

  .totalPoint {
    margin-top: 20px;
    font-weight: 700;
    font-size: 14px;
  }

  .raffleContent img {
    width: 75%;
  }

  .raffleTitle {
    margin: 1% 0 3vh 0;
  }

  .firstButton,
  .raffleButton {
    width: 55%;
  }

  .raffleContent {
    margin-top: 0;
  }

  .raffleCancelButton {
    margin-top: 5px;
    font-size: 12px;
  }
  .wheel {
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    position: absolute;
  }
}

@media (min-width: 1100px) {
  .copyField:hover {
    cursor: pointer;
    background-color: rgb(224, 224, 224);
  }
  .wheel {
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    position: absolute;
  }
}
