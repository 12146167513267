.page {
  position: fixed;
  top: 0%;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background: white;
  z-index: 255;
}

.inline {
  position: initial;
}

.animation {
  width: 90px;
}

@media (min-width: 600px) {
  .animation {
    width: 130px;
  }
}
