.container {
  width: 100%;
}

.input {
  position: relative;
  width: 100%;
  border-bottom: 1px solid lightgray;
  box-sizing: border-box;
}

.input label {
  position: absolute;
  top: 13px;
  left: 4%;
  font-weight: 700;
  font-size: 14px;
  color: var(--black);
  opacity: 0.4;
  transition: top 0.3s ease-in-out, font 0.3s;
  pointer-events: none;
}

.input .focused {
  top: -5px;
  font-size: 11px;
}

.input select {
  background: none;
  outline: none;
  width: 100%;
  height: 45px;
  padding-left: 3%;
  border: 0;
  padding-top: 6px;
  box-sizing: border-box;
}

.warning {
  color: var(--algida-red);
  font-size: 12px;
}
